<template>
	<div>
		<v-stepper
			class="elevation-0"
			v-model="e6"
			vertical
			>
			<v-stepper-step
				:complete="e6 > 1"
				step="1"
				@click="e6 = 1"
				:rules="step1"
				>
				<span class="stepper-step-proceed">Account Information</span>
			</v-stepper-step>

			<v-stepper-content step="1">
				<v-card
					:elevation="0"
					class="mb-6"
					>
						<v-form ref="step1" v-model="step1form">
							<account-information :disabled="true" />
						</v-form>
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="$router.push({path: '/sales-prospecting'})"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 2"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 2"
				step="2"
        @click="e6 = 2"
				:rules="step2"
				>
				<span class="stepper-step-proceed">PRP Risk Assessment Tool</span>
			</v-stepper-step>

			<v-stepper-content step="2">
				<v-card
					:elevation="0"
					class="mb-6"
					>
					<v-form ref="step3" v-model="step2form">
						<ReAccreditationRiskTool :disabled="true"/>
					</v-form>
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 3"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 3"
				step="3"
        @click="e6 = 3"
				:rules="step3"
				>
				<span class="stepper-step-proceed">Supporting Documents</span>
			</v-stepper-step>

			<v-stepper-content step="3">
				<v-card
					class="mb-1"
					:elevation="0"
					>
					<supporting-documents :disabled="true" />
				</v-card>
				<v-divider class="my-5"></v-divider>
				<v-row>
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 4"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

      <v-stepper-step
      :complete="e6 > 4"
				step="4"
        @click="e6 = 4"
				:rules="step4"
						>
            <span class="stepper-step-proceed">
              Re-Accreditation Check List 
              <span style="font-weight: bold">({{ salesProspectingForm.businessType}})</span>
            </span>
					</v-stepper-step>

					<v-stepper-content step="4">
						<v-card
							class="mb-12"
							:elevation="0"
							>

							<v-form
							ref="soleProprietorForm"
							v-model="soleProprietor"
							>
								<sole-proprietor v-if="salesProspectingForm.businessType === 'Single Proprietor'" :disabled="true" />
							</v-form>
							<v-form
							ref="cooperativeForm"
							v-model="cooperativeHybrid"
							>
								<cooperative-hybrid v-if="salesProspectingForm.businessType === 'Cooperative/Hybrid'" :disabled="true"/>
							</v-form>
							<v-form
							ref="corporationForm"
							v-model="corporationModel"
							>
								<corporation v-if="salesProspectingForm.businessType=== 'Corporation'" :disabled="true"/>
							</v-form>
							
						</v-card>
						<v-divider></v-divider>
						<v-row class="mt-2">
							<v-col>
								<v-btn
                  color="primary"
                  @click="e6 -= 1"
                  outlined
                  >
                  <b>Back</b>
                </v-btn>
								<v-btn
									@click="e6 = 5"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

          <v-stepper-step 
						step="5" 
						:complete="e6 > 5" 
						@click="e6 = 5"
						:rules="step5"
						>
						<span class="stepper-step-proceed">Compliance Officer/Delegate</span>
					</v-stepper-step>
					<v-stepper-content step="5">
						<v-card
							:elevation="0"
							>
							<v-form ref="step4" v-model="step4form">
								<ComplianceOfficer :disabled="true" />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 6"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

          <v-stepper-step step="6" 
						:complete="e6 > 6" 
						@click="e6 = 6"
						:rules="step6"
						>
						<span class="stepper-step-proceed">Ownership</span>
					</v-stepper-step>
					<v-stepper-content step="6">
						<v-card
							class="mb-12"
							:elevation="0"
							>
							<Owners :disabled="true" />
							<v-textarea
								v-if="currUser.user_role !== 'encoder' && !$route.query.viewOnly"
								label="Comment"
								hide-details="auto"
								outlined
								dense
								@change="change"
							></v-textarea>
							<v-divider></v-divider>
							<!-- <v-form ref="ownerToUpload" v-model="ownerToUpload">
								<Owners :disabled="true" />
							</v-form> -->
						</v-card>
						
						<v-divider class="my-5"></v-divider>
						
						<v-row>
							<v-col v-if="salesProspectingForm.businessType === 'Cooperative/Hybrid' || salesProspectingForm.businessType === 'Corporation'">
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>

								<v-btn
									@click="e6 = 7"
									color="info"
									style="float:right;"
									
									>
									<b>Continue</b>
								</v-btn>
                </v-col>
                <v-col v-else>
								<v-btn
									v-if="this.currUser.is_onboarding_committee === '1' && status === 'Onboarding Approval Pending'"
									class="mr-4"
									color="primary"
									style="float:right;"
									@click="approveOnboarding"
									>
									<b>Approve SP</b>
								</v-btn>
								<v-btn
									v-else-if="this.currUser.is_validation_committee === '1' && status === 'Onboarding Approved - Endorsed to Validation Committee'"
									class="mr-4"
									color="primary"
									style="float:right;"
									@click="approveValidation"
									>
									<b>Approve SP</b>
								</v-btn>

                <v-btn
									v-if="this.currUser.is_onboarding_committee === '1' && status === 'Onboarding Approval Pending'"
									@click="disapproveOnboarding"
									color="red"
									class="mr-4"
									style="float:right;"
									>
									<b>Disapprove</b>
								</v-btn>
								<v-btn
									v-else-if="this.currUser.is_validation_committee === '1' && status === 'Onboarding Approved - Endorsed to Validation Committee'"
									@click="disapproveValidation"
									color="red"
									class="mr-4"
									style="float:right;"
									>
									<b>Disapprove</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>
          <v-stepper-step 
						step="7" 
						:complete="e6 > 7" 
						@click="e6 = 7"
						:rules="step7"
            v-if="salesProspectingForm.businessType === 'Cooperative/Hybrid' || salesProspectingForm.businessType === 'Corporation'"
						>
						<span class="stepper-step-proceed">Primary Contact Information</span>
					</v-stepper-step>
					<v-stepper-content step="7">
						<v-card
							:elevation="0"
							>
							<v-form ref="step7" v-model="step7form" v-if="salesProspectingForm.businessType === 'Cooperative/Hybrid' || salesProspectingForm.businessType === 'Corporation'">
								<PrimaryContactInformation :disabled="true" />
							</v-form>
						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 8"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

          <v-stepper-step 
						step="8" 
						:complete="e6 > 8" 
						@click="e6 = 8"
						:rules="step8"
            v-if="salesProspectingForm.businessType === 'Cooperative/Hybrid' || salesProspectingForm.businessType === 'Corporation'"
						>
						<span class="stepper-step-proceed">Branch(s)</span>
					</v-stepper-step>
					<v-stepper-content step="8" v-if="salesProspectingForm.businessType === 'Cooperative/Hybrid' || salesProspectingForm.businessType === 'Corporation'">
						<v-card
							class="mb-12"
							:elevation="0"
							>

							<v-checkbox
								v-if="existingUploadedBranches.length > 1"
								label="Single Location"
								v-model="otherPrimaryDetails.single_location"
								color="indigo"
								hide-details="auto"
								disabled
								class="mb-5"
							></v-checkbox>
							<v-checkbox
								v-else
								label="Single Location"
								color="indigo"
								v-model="otherPrimaryDetails.single_location"
								hide-details="auto"
								class="mb-5"
							></v-checkbox>

							<Branches  @newValue='countBranchPage' :disabled="true" />

							<!-- <div class="text-center mb-5" v-if="existingUploadedBranches.length >= 1">
								<v-pagination
								@input="changePage"
								v-model="page"
								:total-visible="5"
								:length="branchPage.length"
								></v-pagination>
							</div>

							<v-divider></v-divider>
							<v-form v-if="otherPrimaryDetails.single_location && existingUploadedBranches.length == 1">

							</v-form>
							<v-form ref="branchToUpload" v-model="branchToUpload"
							v-else>
								<Branches  />
							</v-form> -->
						</v-card>
						<v-divider></v-divider>
						<v-row class="mt-2">
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									@click="e6 = 9"
									color="info"
									style="float:right;"
									>
									<b>Continue</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

          <v-stepper-step step="9" 
						:complete="e6 > 9" 
						@click="e6 = 9"
						v-if="hasBod == true && salesProspectingForm.businessType === 'Cooperative/Hybrid' || salesProspectingForm.businessType === 'Corporation'"
						>
						<span class="stepper-step-proceed">Board of Directors</span>
					</v-stepper-step>
					<v-stepper-content step="9" v-if="hasBod == true && salesProspectingForm.businessType === 'Cooperative/Hybrid' || salesProspectingForm.businessType === 'Corporation'">

						<v-card
							class="mb-12"
							:elevation="0"
							>
							<BOD :disabled="true"/>
							<v-divider></v-divider>
							<!-- <v-form ref="bodToUpload" v-model="bodToUpload">
								<ReAccreditationNewBOD :disabled="true" />
							</v-form> -->
						<v-textarea
								v-if="currUser.user_role !== 'encoder' && !$route.query.viewOnly && reaccreditationStatus === 'Due For Re-Accreditation'"
								label="Comment"
								hide-details="auto"
								outlined
								dense
								@change="change"
							></v-textarea>

						</v-card>
						<v-divider class="my-5"></v-divider>
						<v-row>
							<v-col>
								<v-btn
									color="primary"
									@click="e6 -= 1"
									outlined
									>
									<b>Back</b>
								</v-btn>
								<v-btn
									v-if="this.currUser.is_onboarding_committee === '1' && status === 'Onboarding Approval Pending'"
									class="mr-4"
									color="primary"
									style="float:right;"
									@click="approveOnboarding"
									>
									<b>Approve SP</b>
								</v-btn>
								<v-btn
									v-else-if="this.currUser.is_validation_committee === '1' && status === 'Onboarding Approved - Endorsed to Validation Committee'"
									class="mr-4"
									color="primary"
									style="float:right;"
									@click="approveValidation"
									>
									<b>Approve SP</b>
								</v-btn>

                <v-btn
									v-if="this.currUser.is_onboarding_committee === '1' && status === 'Onboarding Approval Pending'"
									@click="disapproveOnboarding"
									color="red"
									class="mr-4"
									style="float:right;"
									>
									<b>Disapprove</b>
								</v-btn>
								<v-btn
									v-else-if="this.currUser.is_validation_committee === '1' && status === 'Onboarding Approved - Endorsed to Validation Committee'"
									@click="disapproveValidation"
									color="red"
									class="mr-4"
									style="float:right;"
									>
									<b>Disapprove</b>
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>
        </v-stepper>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import AccountInformation from '../components/modules/salesProspecting/AccountInformation.vue';
import SupportingDocuments from '../components/modules/salesProspecting/ReAccreditationSupportingDocuments.vue';
import SoleProprietor from '../components/modules/salesProspecting/ReAccreditationSoleProprietor.vue';
import CooperativeHybrid from '../components/modules/salesProspecting/ReAccreditationCooperativeHybrid.vue';
import Corporation from '../components/modules/salesProspecting/ReAccreditationCorporation.vue';
import Branches from '../components/modules/salesProspecting/ViewUploadedBranches.vue'
import Owners from '../components/modules/salesProspecting/ViewUploadedOwners.vue';
import BOD from '../components/modules/salesProspecting/ViewUploadedBod.vue';
import ComplianceOfficer from '../components/modules/salesProspecting/ComplianceOfficer.vue';
import PrimaryContactInformation from '../components/modules/salesProspecting/PrimaryContactInformation.vue';
import ReAccreditationRiskTool from '../components/modules/salesProspecting/ReAccreditationRiskTool.vue';

export default {
	components: {
		AccountInformation: AccountInformation,
		SupportingDocuments: SupportingDocuments,
    SoleProprietor: SoleProprietor,
		CooperativeHybrid: CooperativeHybrid,
    Corporation: Corporation,
    Branches: Branches,
    Owners: Owners,
    BOD: BOD,
		// ReAccreditationNewBOD: ReAccreditationNewBOD,
    ComplianceOfficer: ComplianceOfficer,
    PrimaryContactInformation: PrimaryContactInformation,
		ReAccreditationRiskTool: ReAccreditationRiskTool
	},
	async created () {
		const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
    await this.$store.dispatch('salesProspecting/doGetProvince', this.hrisToken)
    this.countBranchPage(resSP.data.result[0].sp_branch)
		this.assignData(resSP.data.result[0])
    this.decodeResUpload(resSP.data.result[0].sp_location)
    this.decodeOwners(resSP.data.result[0].sp_owner)
		this.decodeBranches(resSP.data.result[0].sp_branch.data)
		this.decodeBod(resSP.data.result[0].sp_bod)
		this.decodeComplianceUploads(resSP.data.result[0].sp_compliance_officer_delegate)
		this.decodePrimaryContactInformationUploads(resSP.data.result[0].sp_primary_contact_information)
		this.decodeRiskTool(resSP.data.result[0].sp_primary_details)
	},
	data: () => ({ 
    soleProprietor: true,
		cooperativeHybrid: true,
		corporationModel: true,
    branchToUpload: true,
    ownerToUpload: true,
    bodToUpload: true,
    hasBod: true,
		e6: 1,
		step1: [],
		step2: [],
		step3: [],
		step4: [],
    step5: [],
    step6: [],
    step7: [],
    step8: [],
    step9: [],
		step1form: true,
		step2form: true,
		step3form: true,
    step4form: true,
    step5form: true,
    step6form: true,
    step7form: true,
    step8form: true,
    step9form: true,
		steps: ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7', 'step8', 'step9'],
		status: '',
		reaccreditationStatus: '',
	}),
	computed: {
		...mapGetters({
			salesProspectingForm: 'salesProspecting/salesProspectingForm',
			spNumber: 'salesProspecting/spNumber',
			initialUploaded: 'salesProspecting/initialUploaded',
			existingInitialUploaded: 'salesProspecting/existingInitialUploaded',
      otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails',
      existingUploadedBranches: 'salesProspecting/existingUploadedBranches',
			ReAccreditationDocuments: 'salesProspecting/ReAccreditationDocuments',
			cities: 'salesProspecting/cities',
			countries: 'salesProspecting/countries',
			province: 'salesProspecting/province',
			owners: 'salesProspecting/owners',
			branches: 'salesProspecting/branches',
			primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
			primaryContactInfo: 'salesProspecting/primaryContactInfo',
			complianceOfficer: 'salesProspecting/complianceOfficer',
			existingUploadedFiles: 'salesProspecting/existingUploadedFiles',
			existingUploadedOwners: 'salesProspecting/existingUploadedOwners',
			bsp: 'salesProspecting/bsp',
			existingBSP: 'salesProspecting/existingBSP',
			single_proprietor_upload: 'salesProspecting/single_proprietor_upload',
			bod: 'salesProspecting/bod',
			token: 'auth/token',
			hrisToken: 'auth/hrisToken',
			authTimestamp: 'auth/authTimestamp',
			primaryDetailsRadio1: 'salesProspecting/primaryDetailsRadio1',
			mother_entity_details: 'salesProspecting/mother_entity_details',
      salesProspects: 'salesProspecting/salesProspects',
      salesProspectsReAccreditation: 'salesProspecting/salesProspectsReAccreditation',
      ReAccreditationDetails: 'salesProspecting/ReAccreditationDetails',
      ReAccreditationinitialUploaded: 'salesProspecting/ReAccreditationinitialUploaded',
      documents: 'salesProspecting/documents',
      ReAccreditationComplianceOfficer: 'salesProspecting/ReAccreditationComplianceOfficer',
			existingUploadedCompliance: 'salesProspecting/existingUploadedCompliance',
			ReAccreditationPrimaryContactInfo: 'salesProspecting/ReAccreditationPrimaryContactInfo',
			existingUploadedPrimaryContactInformation: 'salesProspecting/existingUploadedPrimaryContactInformation',
			currUser: 'auth/currUser',
			final_comment: 'salesProspecting/final_comment',
		}),
	},
	methods: {
		countBranchPage(e){
			this.page = 1
			this.branchPage = []
			if(e){
				let number = e.last_page
				for (let i = 1; i <= number; i++) {
					this.branchPage.push(i)
				}
			}
		},
		async changePage(e){
			const payload = {
                id : this.$route.params.id,
                page: e
            }
			const res = await this.$store.dispatch('salesProspecting/changePageBranch', {payload})
			if(res.status === 200) {
				this.decodeBranches(res.data.result[0].sp_branch.data)
			}
		},
    //ASSIGN DATA AND DECODING FILE UPLOADS
		assignData (data) {
			this.status = data.status
			this.reaccreditationStatus = data.sp_primary_details.reaccreditation_status
			this.decodeUploads(data.sp_primary_details)
			var salesProspectingForm = {
				id: '',
				account_name: data.sp_primary_details.account_name,
				legal_entity_name: data.sp_primary_details.legal_entity_name,
				trade_name: data.sp_primary_details.trade_name,
				bp_idno: data.bp_idno,
				scorecard: JSON.parse(data.sp_primary_details.scorecard),
				date_presentation: data.sp_primary_details.date_presentation,
				documents_presented: data.sp_primary_details.documents_presented === null ? [] : JSON.parse(data.sp_primary_details.documents_presented),
				summary_presentation: data.sp_primary_details.summary_presentation,
				accept_or_negotiate: data.sp_primary_details.accept_or_negotiate,
				scorecard_result: data.sp_primary_details.scorecard_result,
				risk_profile: data.sp_primary_details.risk_profile,
				uploaded_loi:  data.sp_primary_details.upload_loi === null ? [] : data.sp_primary_details.upload_loi,
				uploaded_picture:  data.sp_primary_details.upload_picture === null ? [] : data.sp_primary_details.upload_picture,
				uploaded_supp_docs:  data.sp_primary_details.upload_supp_docs === null ? [] : data.sp_primary_details.upload_supp_docs,
				uploaded_kaizer: data.sp_primary_details.upload_kaizer === null ? [] : data.sp_primary_details.upload_kaizer,
				uploaded_amla:  data.sp_primary_details.upload_amla === null ? [] : data.sp_primary_details.upload_amla,
				amla_risk_rating: data.sp_primary_details.amla_risk_rating,
				amla_risk_profile: data.sp_primary_details.amla_risk_profile,
        businessType: data.sp_other_primary_details.type
			}
			this.$store.commit('salesProspecting/SET_SELECTED_SP', salesProspectingForm)
      this.$store.commit('salesProspecting/SET_SALES_PROSPECTS_REACCREDITATION', data)
      if (data.sp_other_primary_details){
				if(data.sp_other_primary_details.type == 'Single Proprietor'){
					this.hasBod = false
				}
			} else if (data.sp_other_primary_details){
				this.hasBod = true
			}

			this.status = data.status
			var details = {}
			details.created_at  = data.created_at
			details.profile = data.profile
			details.status = data.status
			details.account_name = data.sp_primary_details.account_name
			this.$store.commit('salesProspecting/SET_PRIMARY_DETAILS', details)
			if (data.sp_primary_business_address) {
				this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS', data.sp_primary_business_address)
			}
			if (data.sp_primary_contact_information) {
				this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO', data.sp_primary_contact_information) 
			}
			if (data.sp_compliance_officer_delegate) {
				this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER', data.sp_compliance_officer_delegate)
			}
			if (data.sp_other_primary_details){
				data.sp_other_primary_details.list_of_non_ex_remco = JSON.parse(data.sp_other_primary_details.list_of_non_ex_remco)
				data.sp_other_primary_details.list_of_avp_products = JSON.parse(data.sp_other_primary_details.list_of_avp_products)
				data.sp_other_primary_details.business_information = JSON.parse(data.sp_other_primary_details.business_information)
				data.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(data.sp_other_primary_details.business_type_of_sub_agents )
				data.sp_other_primary_details.top_reasons_for_WU = JSON.parse(data.sp_other_primary_details.top_reasons_for_WU)
				data.sp_other_primary_details.typical_customers = JSON.parse(data.sp_other_primary_details.typical_customers)
				this.businessType = data.sp_other_primary_details.type

				this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS', data.sp_other_primary_details)

				if (this.otherPrimaryDetails.is_rsa == true){
					this.$store.commit('salesProspecting/SET_RADIO1', 'Remittance Service Agent/s (RSA)')
				} 

				if (this.otherPrimaryDetails.is_tie_up == true){
					this.$store.commit('salesProspecting/SET_RADIO1', 'Tie-up')
				}

				if (this.otherPrimaryDetails.is_kya_pending == true){
					this.$store.commit('salesProspecting/SET_RADIO2', 'KYA Pending')
				}

				if (this.otherPrimaryDetails.is_kya_recertification == true){
					this.$store.commit('salesProspecting/SET_RADIO2', 'KYA Recertification')
				}

				if (this.otherPrimaryDetails.is_kya_certified == true){
					this.$store.commit('salesProspecting/SET_RADIO2', 'KYA Certified')
				}
						
			}
		},
		//SETTING OF SUPPORT DOCUMENTS
    decodeUploads (resUpload) {
			if (resUpload.upload_loi !== null) {
				resUpload.upload_loi = JSON.parse(resUpload.upload_loi)
			}
			if (resUpload.upload_picture !== null) {
				resUpload.upload_picture = JSON.parse(resUpload.upload_picture)
			}
			if (resUpload.upload_supp_docs !== null) {
				resUpload.upload_supp_docs = JSON.parse(resUpload.upload_supp_docs)
			}
			//Decoding for AML and Kaizer
			if (resUpload.upload_amla !== null) {
					resUpload.upload_amla = JSON.parse(resUpload.upload_amla)
				}
				if (resUpload.upload_kaizer !== null) {
					resUpload.upload_kaizer = JSON.parse(resUpload.upload_kaizer)
				}
			this.$store.commit('salesProspecting/SET_EXISTING_INITIAL_UPLOADED_FILES', resUpload)
		},
		//SETTING OF ONBOARDING DOCUMENTS
    decodeResUpload (resUpload) {
			if (resUpload.length > 0) {

				if (resUpload[0].is_western_union == 1){
					this.$store.commit('salesProspecting/SET_WESTERN_MOA', true)
				}
				if (resUpload[0].is_avp == 1){
					this.$store.commit('salesProspecting/SET_AVP_MOA', true)
				}
				if (resUpload[0].is_nonex == 1){
					this.$store.commit('salesProspecting/SET_NONEX_MOA', true)
				}
				
				var type = resUpload[0].type
				this.businessType = type
				if (type === 'Single Proprietor') {
					resUpload[0].dti = JSON.parse(resUpload[0].dti)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				} else if (type === 'Cooperative/Hybrid') {
					resUpload[0].cda = JSON.parse(resUpload[0].cda)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].secretary_certificate = JSON.parse(resUpload[0].secretary_certificate)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].org_chart = JSON.parse(resUpload[0].org_chart)
					resUpload[0].board_resolution = JSON.parse(resUpload[0].board_resolution)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				} else if (type === 'Corporation') {
					resUpload[0].sec = JSON.parse(resUpload[0].sec)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].gis = JSON.parse(resUpload[0].gis)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].secretary_certificate = JSON.parse(resUpload[0].secretary_certificate)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].org_chart = JSON.parse(resUpload[0].org_chart)
					resUpload[0].board_resolution = JSON.parse(resUpload[0].board_resolution)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
					resUpload[0].moa_avp = JSON.parse(resUpload[0].moa_avp)
					resUpload[0].moa_nonex = JSON.parse(resUpload[0].moa_nonex)
					resUpload[0].amla_questionnaire = JSON.parse(resUpload[0].amla_questionnaire)
					resUpload[0].amla_cor = JSON.parse(resUpload[0].amla_cor)
				}
				this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_FILES', resUpload[0])
			}
		},
		//SETTING DATA FOR OWNERS
    decodeOwners (resOwners) {
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			let nonExpiryFile = {
                file: null
            }
			resOwners.forEach(function(e) {
				e.owner_id = e.id
				e.amlaUploaded = JSON.parse(e.amla)
				e.court_clearanceUploaded = JSON.parse(e.court_clearance)
				e.nbiUploaded = JSON.parse(e.nbi)
				e.picture_2x2Uploaded = JSON.parse(e.picture_2x2)
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiryFile)
				e.court_clearance = _.clone(nonExpiryFile)
				e.nbi = _.clone(nonExpiryFile)
				e.amla = _.clone(expiryFile)
				e.picture_2x2 = _.clone(nonExpiryFile)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_OWNERS', resOwners)
		},
		//SETTING DATA FOR BOD
		decodeBod (resBod){
			let birthdate = {
				menuIssued: false,
                menuExpiry: false
			}
			let nonExpiryFile = {
                file: null
            }
			resBod.forEach(function(e) {
				e.bod_id = e.id
				e.valid_idUploaded = JSON.parse(e.valid_id)
				e.valid_id = _.clone(nonExpiryFile)
				e.amlaUploaded = JSON.parse(e.amla_certificate)
				e.amla_certificate = _.clone(nonExpiryFile)
				e.nbiUploaded = JSON.parse(e.nbi_clearance)
				e.nbi_clearance = _.clone(nonExpiryFile)
				e.menu = _.clone(birthdate)
			})
			this.$store.commit('salesProspecting/SET_EXISTING_BOD', resBod)

		},
		//SETTING DATA FOR BRANCHES
    decodeBranches (resBranches) {
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			resBranches.forEach(function (e) {
				e.branch_id = e.id
				e.businessPermitUploaded = JSON.parse(e.business_permit)
				e.operating_hours = JSON.parse(e.operating_hours)
				e.operating_hours.forEach(function (g) {
					g.isClosed = parseInt(g.isClosed)
				})
				e.coordinates = JSON.parse(e.coordinates)
				e.business_permit = _.clone(expiryFile)
				e.fla.forEach(function (f) {
					f.amlaUploaded  = JSON.parse(f.amla)
					f.drugTestUploaded = JSON.parse(f.drug_test)
					f.nbiUploaded = JSON.parse(f.nbi)
					f.amla = _.clone(expiryFile)
					f.drug_test = _.clone(expiryFile)
					f.nbi = _.clone(expiryFile)
					// f.valid_idUploaded = JSON.parse(f.valid_id)
					// f.valid_id =  _.clone(expiryFile)
				})
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
			this.existingUploadedBranches.map(coords => {
                coords.coordinates.lat = parseFloat(coords.coordinates.lat)
                coords.coordinates.lng = parseFloat(coords.coordinates.lng)    
            })
		},
		//decoding file upload to be store in existing info
		decodeComplianceUploads (resUpload) {
			if (resUpload.amla_certificate !== null) {
				resUpload.amla_certificate = JSON.parse(resUpload.amla_certificate)
			}
			if (resUpload.valid_id !== null) {
				resUpload.valid_id = JSON.parse(resUpload.valid_id)
			}
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_COMPLIANCE', resUpload)
		},
				//decoding file upload to be store in existing info
		decodePrimaryContactInformationUploads (resUpload) {
			if (resUpload.amla_certificate !== null) {
				resUpload.amla_certificate = JSON.parse(resUpload.amla_certificate)
			}
			if (resUpload.valid_id !== null) {
				resUpload.valid_id = JSON.parse(resUpload.valid_id)
			}
			this.$store.commit('salesProspecting/SET_EXISTING_PRIMARY_CONTACT_INFORMATION', resUpload)
		},
		decodeRiskTool (resUpload) {
			const initialPayload ={
				assessment_rating: resUpload.assessment_rating,
				risk_assessment_profile: resUpload.assessment_profile,
			}
			if (resUpload.assessment_upload !== null) {
				resUpload.assessment_upload = JSON.parse(resUpload.assessment_upload)
			}
			this.$store.commit('salesProspecting/SET_EXISTING_RISK_TOOL_DETAILS', initialPayload)
			this.$store.commit('salesProspecting/SET_EXISTING_RISK_TOOL_DOCUMENTS', resUpload.assessment_upload)
		},	
	saveNonAcceptance () {
			const self = this
			self.$store.commit('salesProspecting/SET_RULE_GENERIC')
			self.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
			setTimeout(() => {
				self.partialSteps.forEach(e => {
					if (!self.$refs[e].validate()) {
						self[e] = [() => false]
						self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
					} else {
						self[e] = []
					}
				})
				if (self.$refs.step1.validate() && self.$refs.step2.validate()) {
					self.steps.forEach(e => {
						self[e] = []
					})
					// this.callSave(payload)
				}
			})
		},	
		change(payload){

			this.$store.commit('salesProspecting/SET_FINAL_COMMENT', payload)
		},
		async approveOnboarding() {
			const initialPayload = { comment: this.final_comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}
			const res = await this.$store.dispatch('salesProspecting/doApproveSPByOnboarding', {payload})
			if (res.status === 200) {
				this.$router.push('/sales-prospecting'); //to be changed to sales prospecting existing
			}
		},
		async approveValidation() {
			const initialPayload = { comment: this.final_comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}
			const res = await this.$store.dispatch('salesProspecting/doApproveSPByValidation', {payload})
			if (res.status === 200) {
				this.$router.push('/existing-sales-prospecting'); //to be changed to sales prospecting existing
			}
		},
		async disapproveOnboarding() {
			const initialPayload = { comment: this.final_comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}
			const res = await this.$store.dispatch('salesProspecting/doReturnOnboarding', {payload})
			if (res.status === 200) {
				this.$router.push('/sales-prospecting'); //to be changed to sales prospecting existing
			}
		},
		async disapproveValidation() {
			const initialPayload = { comment: this.final_comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}
			const res = await this.$store.dispatch('salesProspecting/doReturnValidation', {payload})
			if (res.status === 200) {
				this.$router.push('/existing-sales-prospecting'); //to be changed to sales prospecting existing
			}
		},
		computeFinalRating () {
			if(typeof this.ReAccreditationDetails.scorecard !== 'string'){
				var total = 0
				this.ReAccreditationDetails.scorecard.forEach(function(e){
					e.criteria.forEach(function(f){
						total += (parseInt(f.score) * (f.weight / f.metrics.length).toFixed(2))
					})
				})
				if(isNaN(total)){
					return ''
				}
				return total.toFixed(2)
			}
		}
	}
};
</script>
<style scoped>
	.stepper-step-proceed {
		cursor: pointer;
	}
	.stepper-step-forbidden {
		cursor: no-drop;
	}
</style>

